<template>
	<div>
		<h1 class="text-h5 q-pb-md q-my-md text-left fields-tab-header">
			Devices for {{user_instance.address_string}}
		</h1>

		<div class="settings-wrapper full-width">
			<q-input
				outlined
				v-model="user_instance.email"
				:label="'Work email'"
				:name="'Work email'"
				:hint="'Enter a valid email address'"
				:error-message="data_field_error_messages['email']"
				:error="has_error"
			/>
		</div>

		<field-value-input-fields
			class='settings-wrapper'
			v-for="field in device_fields"
			:key="field.id"
			:field="field"
		/>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import FieldValueInputFields from '@/components/users/edit/data/FieldValueInputFields'

export default {
	name: 'Devices',
	meta: {
		title: 'Alert Cascade - Manage Users'
	},
	components: { FieldValueInputFields },

	computed: {
		...mapGetters(['device_fields', 'user_instance', 'data_field_error_messages']),
		has_error() {
			if (this.data_field_error_messages['email']) {
				return true
			} else {
				return false
			}
		}
	},
}
</script>
